import { createStore } from "vuex";

export default createStore({
  state: {
    host: "https://admin.mobgroup.kz/api",
    isLogin: false,
    showPreloader: false,
    signals: [],
    total: null,
    page: 1,
    pages: 1,
    sortArray: [],
    filterArray: [],
    searchData: {
      type: "phone",
    },
    responceTime: "",
  },
  getters: {},
  mutations: {
    loginToggle(state) {
      state.isLogin = !state.isLogin;
    },
    togglePreloader(state) {
      state.showPreloader = !state.showPreloader;
    },
    setIsLogin(state, val) {
      state.isLogin = val;
    },
    setSignals(state, list) {
      state.signals = list.map((signal) => {
        return {
          id: signal.app_id,
          status: signal.status_name,
          date: signal.date,
          subject: signal.company,
          region: signal.region_name,
          district: signal.district_name,
        };
      });
    },
    setPage(state, page) {
      state.page = page;
    },
    setTotalCount(state, count) {
      state.total = count;
    },
    setTotalPages(state, pages) {
      state.pages = pages;
    },
    setResponceTime(state, avg) {
      state.responceTime = `${avg.hours !== 0 ? avg.hours + "ч." : ""} ${
        avg.minutes
      } мин.`;
    },
    setSort(state, params) {
      let isSorted = false;

      state.sortArray = state.sortArray.map((item) => {
        if (item.attribute_name === params.name) {
          isSorted = !isSorted;

          item = {
            attribute_name: params.name,
            order: params.order,
          };
        }

        return item;
      });

      if (!isSorted) {
        state.sortArray.push({
          attribute_name: params.name,
          order: params.order,
        });
      }
    },
    removeSort(state, name) {
      state.sortArray = state.sortArray.filter((item) => {
        if (item.attribute_name !== name) {
          return item;
        }
      });
    },
    setFilter(state, params) {
      let isSorted = false;

      state.filterArray = state.filterArray.map((item) => {
        if (item.attribute_name === params.name) {
          isSorted = !isSorted;

          item = {
            preoperator: "AND",
            attribute_name: params.name,
            predicate: params.predicate || "=",
            value: params.value,
            postoperator: "",
          };
        }

        return item;
      });

      if (!isSorted) {
        state.filterArray.push({
          preoperator: "AND",
          attribute_name: params.name,
          predicate: params.predicate || "=",
          value: params.value,
          postoperator: "",
        });
      }
    },
    removeFilter(state, name) {
      state.filterArray = state.filterArray.filter((item) => {
        if (item.attribute_name !== name) {
          return item;
        }
      });
    },
    setSearchType(state, val) {
      state.searchData.type = val;
    },
    setSearchQuery(state, val) {
      state.searchData.value = val;
    },
  },
  actions: {
    loginUser(store, payload) {
      return fetch(this.state.host + "/admin/auth", {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "x-application-token": "300eaa14-5567-4752-8be5-54ddc05829d7",
        },
        body: JSON.stringify({
          login: payload.login,
          password: payload.password,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.success === "ok") {
            store.commit("loginToggle");
          }
        });
    },
    checkAuth(store) {
      return fetch(this.state.host + "/admin/checkAuth", {
        method: "GET",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 401) {
            store.commit("setIsLogin", false);

            return false;
          } else {
            store.commit("setIsLogin", true);

            return true;
          }
        });
    },
    logoutUser(store) {
      return fetch(this.state.host + "/admin/logout", {
        method: "GET",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
      }).then(() => {
        store.commit("setIsLogin", true);
      });
    },
    getApplications(store) {
      store.commit("togglePreloader");

      return fetch(this.state.host + "/admin/getApplications", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify({
          filters: store.state.filterArray,
          sort: store.state.sortArray,
          search: store.state.searchData.value ? store.state.searchData : null,
          page: store.state.page,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          store.commit("setSignals", res.data);
          store.commit("setTotalCount", res.count);
          store.commit("setTotalPages", res.pages);
          store.commit("setResponceTime", {
            hours: res.avg && res.avg.hours ? res.avg.hours : 0,
            minutes: res.avg && res.avg.minutes ? res.avg.minutes : 0,
          });
        })
        .then(() => {
          store.commit("togglePreloader");
        });
    },
    getApplicationsFile(store) {
      store.commit("togglePreloader");

      return fetch(this.state.host + "/admin/getApplicationsFile", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify({
          filters: store.state.filterArray,
          sort: store.state.sortArray,
          search: store.state.searchData.value ? store.state.searchData : null,
          page: store.state.page,
        }),
      })
        .then((res) => res.blob())
        .then((res) => {
          const forceFileDownload = (response, fileName) => {
            const url = window.URL.createObjectURL(res);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          };

          forceFileDownload(res, "Export " + Date.now() + ".xlsx");
        })
        .then(() => {
          store.commit("togglePreloader");
        });
    },
  },
  modules: {},
});
