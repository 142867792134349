<template>
  <div
    class="modal animate__animated animate__fadeIn"
    v-if="show"
    @click.self="this.$emit('close')"
  >
    <div class="modal-content animate__animated animate__fadeInUp">
      <button class="modal__close" @click="this.$emit('close')">
        <img src="../../assets/icons/close.svg" />
      </button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignalModal",
  props: {
    show: {
      type: Boolean,
      reqired: true,
    },
  },
};
</script>
