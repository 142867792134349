<template>
  <div class="form-group">
    <button class="clear-filter" v-if="selectedOption.id" @click="clearSelect">
      <img src="@/assets/icons/close-filter.svg" />
    </button>
    <span class="arrow">
      <img src="@/assets/icons/arrow-bottom.svg" />
    </span>
    <label :for="id">{{ label }}</label>
    <input
      :name="name"
      :id="id"
      class="select"
      :disabled="disabled"
      :value="selectedOption.label"
      contenteditable="true"
      @click.prevent.self="
        (e) => {
          dropdownToggle();
          e.target.blur();
        }
      "
    />
    <div
      class="select__dropdown animate__animated animate__fadeIn"
      v-click-outside="onClickOutside"
      v-if="showDropdown"
      v-show="options.length"
    >
      <ul class="select__list">
        <li
          class="select__item"
          v-for="option in options"
          :key="option.id"
          :data-value="option.value"
          v-on:click="selectOption($event, option)"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";

export default {
  name: "SelectC",
  props: {
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    defaultOption: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      showDropdown: false,
      selectedOption: {
        id: null,
        value: null,
        label: this.defaultOption,
      },
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    onClickOutside() {
      this.showDropdown = false;
    },
    dropdownToggle() {
      this.showDropdown = !this.showDropdown;
    },
    clearSelect() {
      this.showDropdown = false;
      this.selectedOption = {
        id: null,
        value: null,
        label: this.defaultOption,
      };

      this.$emit("clear", this.name);
    },
    selectOption: function (e, option) {
      this.selectedOption = option;
      this.showDropdown = false;

      this.$emit("selectItem", {
        selectName: this.name,
        value: this.selectedOption.value,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
