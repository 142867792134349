<template>
  <header class="main__header main-header">
    <div class="container main-header__top">
      <table-search></table-search>
      <div class="main-header__logout">
        <button class="logout-button" @click="logoutUser">
          Выйти из кабинета
          <img src="../assets/icons/logout.svg" alt="" />
        </button>
      </div>
    </div>
    <table-filter></table-filter>
  </header>
</template>

<script>
import TableFilter from "@/components/TableFilter";
import TableSearch from "@/components/TableSearch";

export default {
  name: "HeaderComponent",
  components: { TableFilter, TableSearch },
  methods: {
    logoutUser() {
      this.$store.commit("setIsLogin", false);

      this.$store.dispatch("logoutUser").finally(() => {
        this.$router.push("/auth");
      });
    },
  },
};
</script>

<style scoped>
.main__header {
  padding: 24px 0;
}
</style>
