<template>
  <div class="auth-page">
    <div class="auth-page__logo">
      <img src="../assets/gerb.png" width="92" />
    </div>
    <div class="auth-page__login animate__animated animate__fadeIn">
      <div class="login-form">
        <h2 class="login-form__title">Авторизация</h2>
        <div class="form-group">
          <label for="login">Логин</label>
          <input
            class="input"
            type="text"
            id="login"
            v-model="userLogin"
            :class="{ error: isLoginWrong }"
          />
        </div>
        <div class="form-group">
          <label for="password">Пароль</label>
          <input
            class="input"
            type="password"
            id="password"
            v-model="userPass"
            :class="{ error: isPassWrong }"
          />
        </div>
        <div class="check-group">
          <input
            type="checkbox"
            class="input"
            id="remember"
            v-model="rememberUser"
          />
          <label for="remember">Запомнить меня</label>
        </div>
        <div class="form-group" v-if="showError">
          <label style="color: red">Не правильный логин или пароль!</label>
        </div>
        <div class="button-group">
          <button
            class="button"
            @click="
              () => {
                if (formValidate()) {
                  loginUser();
                }
              }
            "
          >
            Войти
          </button>
        </div>
        <div
          class="preloader animate__animated animate__fadeIn"
          v-if="showPreloader"
        >
          <div class="spinner"></div>
        </div>
      </div>
    </div>
    <div class="auth-page__logo">
      <img src="../assets/logo.png" width="134" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthView",
  data() {
    return {
      userLogin: "",
      userPass: "",
      isLoginWrong: false,
      isPassWrong: false,
      rememberUser: false,
      showError: false,
      showPreloader: false,
    };
  },
  methods: {
    formValidate() {
      this.isLoginWrong = this.userLogin === "";
      this.isPassWrong = this.userPass === "";

      return !this.isLoginWrong && !this.isPassWrong;
    },
    loginUser() {
      this.showPreloader = true;

      this.$store
        .dispatch("loginUser", {
          login: this.userLogin,
          password: this.userPass,
        })
        .then(() => {
          if (this.$store.state.isLogin) {
            this.$router.push("/panel");
            this.showError = true;
          } else {
            this.showError = true;
          }

          this.showPreloader = false;
        });
    },
  },
  beforeMount() {
    if (this.$store.state.isLogin) {
      this.$router.push("/panel");
    }
  },
};
</script>

<style>
body {
  background: #07365c;
}
.login-form {
  position: absolute;
}

.login-form .preloader {
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.72);
}
</style>
