<template>
  <div class="table__row" :data-id="item.id">
    <div>{{ item.id }}</div>
    <div
      :class="{
        red: item.status === 'Ложный вызов',
        blue: item.status === 'Ожидает обработки',
        green1: item.status === 'Разъяснено',
        green: item.status === 'Удовлетворено',
      }"
    >
      {{ item.status }}
    </div>
    <div>{{ item.date }}</div>
    <div>{{ item.subject }}</div>
    <div>{{ item.region }}</div>
    <div>{{ item.district }}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.red {
  color: #d70505;
}

.blue {
  color: #0076cc;
}

.green,
.green1 {
  color: #10c222;
}
</style>
