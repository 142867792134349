<template>
  <footer>
    <div class="container">
      <div class="reaction-time">
        Среднее время реагирования: {{ this.$store.state.responceTime }}
      </div>
      <pagination-c></pagination-c>
      <div class="download-file">
        <button class="button button--excel" @click="getFile">
          Скачать файл
        </button>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  methods: {
    getFile() {
      this.$store.dispatch("getApplicationsFile");
    },
  },
};
</script>
