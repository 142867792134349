<template>
  <div class="main">
    <header-component></header-component>
    <table-component></table-component>
    <footer-component></footer-component>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "HomeView",
  components: {
    TableComponent,
    HeaderComponent,
    FooterComponent,
  },
  beforeMount() {
    this.$store.dispatch("getApplications");
  },
};
</script>

<style>
body {
  background: #e8ebee;
}
.main {
  background: #fff;
  border-radius: 7px;
  height: 100%;
  box-shadow: 0px 0px 12px 4px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
}
</style>
