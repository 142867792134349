<template>
  <div class="search-form main-header__search">
    <div class="form-group search-form__input-group">
      <input
        ref="searchInput"
        :type="inputType"
        class="input"
        placeholder="Поиск по номеру телефона"
        @input="setSearchQuery"
        @keyup.enter="searchSignals"
      />
      <button class="form-group__air-btn" @click="searchSignals">
        <img src="../assets/icons/search.svg" alt="" />
      </button>
    </div>
    <div class="search-form__filter">
      <div class="check-group search-form__check-group">
        <input
          class="input"
          name="search-for"
          id="id"
          type="radio"
          value="phone"
          @change="setSearchType('phone')"
          checked
        />
        <label for="id">номер телефона</label>
      </div>
      <div class="check-group search-form__check-group">
        <input
          class="input"
          name="search-for"
          id="id1"
          type="radio"
          value="id"
          @change="setSearchType('id')"
        />
        <label for="id1">номер сигнала</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableSearch",
  data() {
    return {
      inputType: "tel",
    };
  },
  methods: {
    setSearchType(val) {
      this.clearSearchQuery();

      this.inputType =
        this.$store.state.searchData.type === "phone" ? "number" : "tel";
      this.$store.commit("setSearchType", val);
    },
    setSearchQuery(e) {
      if (this.$store.state.searchData.type === "phone") {
        const x = e.target.value
          .replace(/\D/g, "")
          .match(/(^\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
        let clearPhone = "";

        if (e.target.value !== "" && e.target.value !== "+") {
          clearPhone = !x[3]
            ? `7${x[2]}`
            : `7${x[2]}${x[3]}${x[4] ? `${x[4]}` : ""}${x[5] ? `${x[5]}` : ""}`;

          e.target.value = !x[3]
            ? `+7${x[2]}`
            : `+7 (${x[2]}) ${x[3]}${x[4] ? `-${x[4]}` : ""}${
                x[5] ? `-${x[5]}` : ""
              }`;
        }

        this.$store.commit("setSearchQuery", clearPhone);

        return;
      }

      this.$store.commit("setSearchQuery", e.target.value);

      return;
    },
    clearSearchQuery() {
      this.$refs.searchInput.value = "";

      this.$store.commit("setSearchQuery", "");
    },
    searchSignals() {
      this.$store.state.page = 1;
      this.$store.dispatch("getApplications");
    },
  },
};
</script>
