<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  beforeCreate() {
    this.$store.dispatch("checkAuth").then(() => {
      if (this.$store.state.isLogin) {
        this.$router.push("/panel");
      } else {
        this.$router.push("/auth");
      }
    });
  },
};
</script>

<style lang="scss">
:root {
  --animate-duration: 0.25s;
  --animate-delay: 0.1s;
  --animate-repeat: 1;
}

@import "@/assets/css/animate.min.css";
@import "@/scss/main.scss";
@import "@/assets/css/preloader.css";
</style>
