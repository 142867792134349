<template>
  <div class="container main-header__bottom main-filter">
    <select-c
      :name="'region'"
      :id="'region-select'"
      :label="'Область'"
      :default-option="'Выберите область'"
      :options="regions"
      @selectItem="
        (filter) => {
          setFilter(filter);
          this.selectedRegion = filter.value;
          getDistricts();
        }
      "
      @clear="
        (filterName) => {
          this.selectedRegion = null;
          clearFilter(filterName);
          clearFilter('district');
          this.$refs.districtSelect.clearSelect();
        }
      "
    ></select-c>

    <select-c
      :name="'district'"
      :id="'district-select'"
      :label="'Район'"
      :default-option="'Выберите район'"
      :options="districts"
      ref="districtSelect"
      :disabled="districts.length === 0 || this.selectedRegion === null"
      @selectItem="setFilter"
      @clear="clearFilter"
    ></select-c>

    <select-c
      :name="'status'"
      :id="'status-select'"
      :label="'Статус'"
      :options="statuses"
      :default-option="'Выбирите статус'"
      @selectItem="setFilter"
      @clear="clearFilter"
    ></select-c>

    <div class="form-group form-group__date">
      <button class="clear-filter" v-if="selectedDates" @click="clearDates">
        <img src="@/assets/icons/close-filter.svg" />
      </button>
      <label for="">Даты</label>
      <input
        type="text"
        class="input"
        :value="selectedDateText"
        placeholder="дд.мм.гггг  —  дд.мм.гггг"
        @click.prevent="this.showDatePicker = !this.showDatePicker"
      />
      <DatePicker
        v-model="selectedDates"
        v-show="showDatePicker"
        is-range
        class="input__date-picher animate__animated animate__fadeIn"
      />
    </div>

    <button class="button" @click="applyFilters">Применить</button>
  </div>
</template>

<script>
export default {
  name: "TableFilter",
  data() {
    return {
      regions: [],
      selectedRegion: null,
      districts: [],
      statuses: [],
      showDatePicker: false,
      selectedDates: null,
    };
  },
  methods: {
    setFilter(filter) {
      this.$store.commit("setFilter", {
        name: filter.selectName,
        value: filter.value,
      });
    },
    setDateFilter(name, dateStart, predicate) {
      this.$store.commit("setFilter", {
        name: name,
        value: dateStart,
        predicate,
      });
    },
    clearFilter(filterNmae) {
      this.$store.commit("removeFilter", filterNmae);
    },
    clearDates() {
      this.selectedDateText = null;
      this.selectedDates = null;
      this.showDatePicker = null;

      this.$store.commit("removeFilter", "dateFrom");
      this.$store.commit("removeFilter", "dateTo");
    },
    getDistricts() {
      return fetch(
        this.$store.state.host +
          "/application/districts/" +
          this.selectedRegion,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "same-origin",
        }
      )
        .then((res) => res.json())
        .then((res) => {
          this.districts = res.map((item) => {
            return { id: item.id, label: item.name, value: item.id };
          });
        });
    },
    applyFilters() {
      this.$store.state.page = 1;
      this.$store.dispatch("getApplications");
    },
    hideDatepicker() {
      this.showDatePicker = false;
    },
  },
  computed: {
    selectedDateText() {
      if (this.selectedDates) {
        let startDate = new Date(this.selectedDates.start);
        let endDate = new Date(this.selectedDates.end);
        let startMotnh =
          startDate.getMonth() < 9
            ? `0${startDate.getMonth() + 1}`
            : startDate.getMonth() + 1;
        let endMonth =
          endDate.getMonth() < 9
            ? `0${endDate.getMonth() + 1}`
            : endDate.getMonth() + 1;

        this.setDateFilter(
          "dateFrom",
          startDate.getFullYear() +
            "-" +
            startMotnh +
            "-" +
            startDate.getDate(),
          ">="
        );
        this.setDateFilter(
          "dateTo",
          endDate.getFullYear() + "-" + endMonth + "-" + endDate.getDate(),
          "<="
        );

        this.hideDatepicker();

        return (
          startDate.toLocaleDateString("ru-RU") +
          " — " +
          endDate.toLocaleDateString("ru-RU")
        );
      }

      return null;
    },
  },
  beforeMount() {
    fetch(this.$store.state.host + "/application/regions", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
      .then((res) => res.json())
      .then((res) => {
        this.regions = res.map((item) => {
          return { id: item.id, label: item.name, value: item.id };
        });
      });

    fetch(this.$store.state.host + "/application/statuses", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "same-origin",
    })
      .then((res) => res.json())
      .then((res) => {
        this.statuses = res.map((item) => {
          return { id: item.id, label: item.name, value: item.id };
        });
      });
  },
};
</script>
