<template>
  <div class="th" @click="changeSort">
    {{ title }}
    <div class="sort">
      <i
        class="sort-by-asc"
        :class="{ 'sort-by-asc-active': enableSortByDesc }"
      ></i>
      <i
        class="sort-by-desc"
        :class="{ 'sort-by-desc-active': enableSortByAsc }"
      ></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      enableSortByAsc: false,
      enableSortByDesc: false,
    };
  },
  methods: {
    changeSort() {
      if (!this.enableSortByAsc && !this.enableSortByDesc) {
        this.enableSortByAsc = true;
        this.enableSortByDesc = false;

        this.$store.commit("setSort", { name: this.name, order: "ASC" });
        this.$store.dispatch("getApplications");

        return;
      }

      if (this.enableSortByAsc && !this.enableSortByDesc) {
        this.enableSortByAsc = false;
        this.enableSortByDesc = true;

        this.$store.commit("setSort", { name: this.name, order: "DESC" });
        this.$store.dispatch("getApplications");

        return;
      }

      if (!this.enableSortByAsc && this.enableSortByDesc) {
        this.enableSortByAsc = false;
        this.enableSortByDesc = false;

        this.$store.commit("removeSort", this.name);
        this.$store.dispatch("getApplications");

        return;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.th {
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
}
.sort {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.sort-by-desc-active {
  border-top-color: #07365c !important;
}
.sort-by-asc-active {
  border-bottom-color: #07365c !important;
}

.sort-by-asc {
  display: inline-block;
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-bottom: solid 7px #d9d9d9;
  border-top-width: 0;
}

.sort-by-desc {
  display: inline-block;
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-top: solid 7px #d9d9d9;
  border-bottom-width: 0;
}
</style>
