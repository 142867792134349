<template>
  <div class="table-wrapper" ref="tableWrapper">
    <div class="table-head">
      <div class="container">
        <div class="table">
          <div class="table__row">
            <sort-th :title="'Номер'" :name="'id'"></sort-th>
            <sort-th :title="'Статус'" :name="'status'"></sort-th>
            <sort-th :title="'Дата сигнала'" :name="'date'"></sort-th>
            <div>Название юр.лица или ИП</div>
            <sort-th :title="'Область'" :name="'region'"></sort-th>
            <sort-th :title="'Район'" :name="'district'"></sort-th>
          </div>
        </div>
      </div>
    </div>
    <div class="table-body">
      <div class="container">
        <div class="table" v-if="items.length > 0">
          <table-row
            v-for="item in items"
            :key="item.id"
            :item="item"
            @dblclick="openModal(item.id)"
          ></table-row>
        </div>
        <div class="table__not-found" v-else>
          По данному запросу ничего не найдено!
        </div>
      </div>
    </div>

    <div
      v-if="this.$store.state.showPreloader"
      class="preloader animate__animated animate__fadeIn"
    >
      <div class="spinner"></div>
    </div>
  </div>
  <signal-modal :show="showModal" @close="closeModal">
    <div class="signal">
      <h3 class="signal__title">Номер сигнала №{{ selectedSignal.number }}</h3>
      <ul class="signal__statuses">
        <li
          class="signal__status status"
          v-for="status in selectedSignal.statuses"
          :key="status.statusId"
        >
          <span
            class="status__name"
            :class="{
              yellow: status.title === 'Входящий сигнал',
              blue:
                status.title === 'Ожидает обработки' ||
                status.title === 'В работе',
              green:
                status.title === 'Разъяснено' ||
                status.title === 'Удовлетворено',
              red: status.title === 'Ложный вызов',
            }"
          >
            {{ status.title }}
          </span>
          <span class="status__date">{{ status.date }}</span>
        </li>
      </ul>
      <div class="signal__info">
        <div class="signal__field signal-field">
          <span class="signal-field__label">ФИО заявителя</span>
          <span class="signal-field__value">{{ selectedSignal.name }}</span>
        </div>
        <div class="signal__field signal-field">
          <span class="signal-field__label">Название юр. лица или ИП</span>
          <span class="signal-field__value">{{ selectedSignal.subject }}</span>
        </div>
        <div class="signal__field signal-field">
          <span class="signal-field__label">Контактный номер</span>
          <span class="signal-field__value">{{ selectedSignal.phone }}</span>
        </div>
        <div class="signal__field signal-field">
          <span class="signal-field__label">Область</span>
          <span class="signal-field__value">{{ selectedSignal.region }}</span>
        </div>
        <div class="signal__field signal-field">
          <span class="signal-field__label">Район</span>
          <span class="signal-field__value">{{ selectedSignal.district }}</span>
        </div>
        <div class="signal__field signal-field">
          <span class="signal-field__label">Адрес</span>
          <span class="signal-field__value">{{ selectedSignal.adress }}</span>
        </div>
        <div class="signal__field signal-field">
          <span class="signal-field__label">Описание проблемы</span>
          <span class="signal-field__value">{{ selectedSignal.desc }}</span>
        </div>
      </div>
      <div class="signal__footer">
        <button class="button" @click="closeModal">Закрыть</button>
      </div>
    </div>
  </signal-modal>
</template>

<script>
import TableRow from "./table/TableRow.vue";
import SortTh from "./table/SortTh.vue";

export default {
  name: "TableComponent",
  components: { TableRow, SortTh },
  props: {
    msg: String,
  },
  data() {
    return {
      selectedSignal: null,
      showModal: false,
    };
  },
  methods: {
    scrollTableUp() {
      this.$nextTick(() => {
        this.$refs.tableWrapper.scrollTop = 0;
      });
    },
    getApplication(id) {
      return fetch(this.$store.state.host + "/admin/getApplication/" + id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          this.selectedSignal = {
            id: res.app_id,
            number: res.app_id,
            statuses: res.status_history.map((item) => {
              return {
                title: item.status_name,
                date: item.time,
              };
            }),
            name: res.name,
            subject: res.company,
            phone: (() => {
              const x = res.phone
                .replace(/\D/g, "")
                .match(/(^\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

              return !x[3]
                ? `+7${x[2]}`
                : `+7 (${x[2]}) ${x[3]}${x[4] ? `-${x[4]}` : ""}${
                    x[5] ? `-${x[5]}` : ""
                  }`;
            })(),
            region: res.region_name,
            district: res.district_name,
            adress: res.address,
            desc: res.problem,
          };
        });
    },
    openModal(id) {
      this.getApplication(id).then(() => {
        this.showModal = true;
      });
    },
    closeModal() {
      this.selectedSignal = null;
      this.showModal = false;
    },
  },
  computed: {
    items: function () {
      this.scrollTableUp();

      return this.$store.state.signals;
    },
  },
};
</script>

<style scoped>
.main__table {
  flex: 1 1 auto;
}
</style>
